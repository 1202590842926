<template>
    <v-row
        class="mx-auto"
        align="center"
        justify="center">
        <v-col
            cols="12"
            sm="8"
            md="4">
            <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form" slim>
                <v-card class="elevation-12">
                    <v-card-text>
                        <v-form ref="form" @submit.prevent="handleSubmit(register)" @keyup.native.enter="handleSubmit(register)">
                            <text-field-with-validation
                                :rules="rules.username"
                                :label="$t('Benutzername')"
                                prepend-icon="$person"
                                name="username"
                                autofocus
                                :loading="validating.username ? 'warning' : false"
                                :append-icon="validating.username ? '$spinner' : ''"
                                @blur="checkUsername"
                                @focus="abortUsernameCheck"
                                v-model="form.username"/>

                            <text-field-with-validation
                                :rules="rules.email"
                                :label="$t('Email')"
                                prepend-icon="$envelope"
                                name="email"
                                type="email"
                                :loading="validating.email ? 'warning' : false"
                                :append-icon="validating.email ? '$spinner' : ''"
                                @blur="checkEmail"
                                @focus="abortEmailCheck"
                                v-model="form.email"/>

                            <validation-observer>
                                <text-field-with-validation
                                    :rules="rules.password"
                                    v-model="form.password"
                                    prepend-icon="$lock"
                                    :label="$t('Passwort')"
                                    :loading="validating.password ? 'warning' : false"
                                    :append-outer-icon="validating.password ? passwordShowIcon : ''"
                                    name="password"
                                    :type="showPassword ? 'text' : 'password'">
                                    <template slot="append">
                                        <span @click="showHidePassword" class="cursor-pointer">
                                            <v-icon v-html="passwordShowIconWithLoading"/>
                                        </span>
                                    </template>
                                </text-field-with-validation>

                                <text-field-with-validation
                                    :rules="rules.passwordConfirmation"
                                    v-model="form.password_confirmation"
                                    prepend-icon="$lock"
                                    :label="$t('Passwort bestätigen')"
                                    :loading="validating.password ? 'warning' : false"
                                    :append-outer-icon="validating.password ? passwordShowIcon : ''"
                                    name="password_confirmation"
                                    :type="showPassword ? 'text' : 'password'">
                                    <template slot="append">
                                        <span @click="showHidePassword" class="cursor-pointer">
                                            <v-icon v-html="passwordShowIconWithLoading"/>
                                        </span>
                                    </template>
                                </text-field-with-validation>
                            </validation-observer>

                            <checkbox-with-validation
                                :rules="rules.agb"
                                v-model="form.agb"
                                prepend-icon="$clipboardCheck"
                                translationLabel="agb"
                                name="agb">
                                <template v-slot:label>
                                    <div>
                                        {{ $t('register.agbPrivacyBefore') }}
                                        <v-btn text x-small color="primary" @click.stop="openAgb">{{ $t('AGB') }}</v-btn>
                                        /
                                        <v-btn text x-small color="primary" @click.stop="openPrivacy">{{$t('Datenschutzbestimmungen')}}</v-btn>
                                        {{ $t('register.agbPrivacyAfter') }}
                                    </div>
                                </template>
                            </checkbox-with-validation>

                            <v-checkbox
                                v-model="form.newsletter"
                                prepend-icon="$envelopeOpen"
                                append-icon="$info"
                                name="newsletter"
                                @click:append="showNewsletterHint = ! showNewsletterHint"
                                :persistent-hint="showNewsletterHint"
                                :hint="$t('register.newsletter.hint')"
                                :label="$t('register.newsletter.title')"
                            >
                            </v-checkbox>

                            <v-switch
                                v-model="form.remember"
                                :hint="$t('Ohne Speicherung ist der Login nur für diesen Tab gültig, bis du den Browser beendest')"
                                :persistent-hint="true"
                                color="success"
                                :label="$t('Login speichern')"
                            />
                        </v-form>

                        <v-alert
                            class="mt-2"
                            type="error"
                            :value="alert.visible"
                            :color="$store.getters['ColorManager/getErrorAlertColor']"
                            :class="$store.getters['ColorManager/getSystemTextDefaultColor']"
                            :dismissible="true"
                            transition="fade-transition"
                        >
                            <nl2br tag="span" :text="alert.text"/>
                        </v-alert>
                    </v-card-text>

                    <v-card-actions>
                        <router-link :to="getLoginUrl()">
                            <v-btn small outlined>
                                {{ $t('Login') }}
                            </v-btn>
                        </router-link>
                        <v-spacer/>
                        <v-btn small color="success" @click="register" :disabled="invalid" :loading="loading">
                            {{ $t('Account erstellen') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-col>
    </v-row>
</template>

<script>
    import {login}                 from '../../router/links/Urls';
    import * as rules              from '../../Validation/Vee-Validate/UserManagement/Register';
    import TextFieldWithValidation from '../../components/VeeValidate/TextFieldWithValidation';
    import CheckboxWithValidation  from '../../components/VeeValidate/CheckboxWithValidation';
    import {mapMutations}          from 'vuex';
    import {privacy, agb}          from '../../router/links/Components';

    export default {
        data: () => ({
            form:               {
                username:              '',
                password:              '',
                email:                 '',
                password_confirmation: '',
                agb:                   false,
                newsletter:            false,
                remember:              false,
            },
            showNewsletterHint: false,
            showPassword:       false,
            rules:              rules,
            loading:            false,
            validating:         {
                username: false,
                email:    false,
                password: false,
            },
            axios:              {
                username: null,
                email:    null,
            },
            alert:              {
                visible: false,
                text:    '',
            },
        }),

        computed: {
            passwordShowIconWithLoading() {
                if (this.validating.password) {
                    return '$spinner';
                }

                return this.passwordShowIcon;
            },

            passwordShowIcon() {
                return this.showPassword ? '$eyeSlash' : '$eye';
            },
        },

        components: {
            TextFieldWithValidation,
            CheckboxWithValidation,
        },

        notifications: {
            showUnknownRegisterError: {
                titleKey:   'notifications.register.unknown.title',
                messageKey: 'notifications.register.unknown.message',
                type:       'error',
            },

            showRegisterSuccess: {
                titleKey:   'notifications.register.success.title',
                messageKey: 'notifications.register.success.message',
                type:       'success',
            },
        },

        methods: {
            ...mapMutations('System', ['setDialog']),

            openPrivacy() {
                this.setDialog({
                    open:      true,
                    title:     'Datenschutz',
                    component: privacy,
                });
            },

            openAgb() {
                this.setDialog({
                    open:      true,
                    title:     'AGBs',
                    component: agb,
                });
            },

            abortUsernameCheck() {
                if (this.axios.username) {
                    this.axios.username.cancel();
                    this.axios.username      = null;
                    this.validating.username = false;
                }
            },

            checkUsername() {
                this.abortUsernameCheck();

                if (this.form.username === '' || this.loading) {
                    return;
                }

                this.axios.username = this.$axiosToken.source();

                this.validating.username = true;
                this.$axios.get(
                    this.route('registration.usernameAvailable', {
                        username: this.form.username,
                    }),
                    {
                        cancelToken: this.axios.username.token,
                    },
                ).catch((error) => {
                    this.handleAxiosGlobalResponseErrors(error, this.$refs.form);
                }).finally(() => {
                    this.axios.username      = null;
                    this.validating.username = false;
                });
            },

            abortEmailCheck() {
                if (this.axios.email) {
                    this.axios.email.cancel();
                    this.axios.email      = null;
                    this.validating.email = false;
                }
            },

            checkEmail() {
                this.abortEmailCheck();

                if (this.form.email === '' || this.loading) {
                    return;
                }

                this.axios.email = this.$axiosToken.source();

                this.validating.email = true;
                this.$axios.get(
                    this.route('registration.emailAvailable', {
                        email: this.form.email,
                    }),
                    {
                        cancelToken: this.axios.email.token,
                    },
                ).catch((error) => {
                    this.handleAxiosGlobalResponseErrors(error, this.$refs.form);
                }).finally(() => {
                    this.axios.email      = null;
                    this.validating.email = false;
                });
            },

            register() {
                this.abortUsernameCheck();
                this.abortEmailCheck();
                this.alert.visible = false;

                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$store.dispatch('activateLoader');

                this.$axios.post(
                    this.route('registration.registerWithLogin'),
                    {
                        username:              this.form.username,
                        password:              this.form.password,
                        password_confirmation: this.form.password_confirmation,
                        agb:                   this.form.agb ? 1 : 0,
                        newsletter:            this.form.newsletter ? 1 : 0,
                        email:                 this.form.email,
                    },
                ).then((response) => {
                    const responseData = response.data;

                    this.$store.dispatch('User/login', {
                        ...responseData,
                        remember: this.form.remember,
                    });
                    this.showRegisterSuccess();
                }).catch((error) => {
                    const responseData = this.handleAxiosGlobalResponseErrors(error, this.$refs.form);

                    if (responseData.responseAvailable) {
                        if (responseData.form !== true && responseData.hasAdditional === true) {
                            this.alert.text               = responseData.additional[0];
                            this.alert.visible            = true;
                            this.$refs.form.flags.invalid = true;
                        }
                    } else {
                        this.$logger.error('Register Error', [
                            error.response,
                            error.request,
                            error.message,
                        ], 'register');
                        this.showUnknownRegisterError();
                    }
                }).finally(() => {
                    this.loading = false;
                    this.$store.dispatch('deactivateLoader');
                });
            },

            getLoginUrl() {
                return login;
            },

            showHidePassword() {
                this.showPassword = ! this.showPassword;
            },
        },
    };
</script>

import '../Rules/Email';
import '../Rules/Min';
import '../Rules/Max';
import '../Rules/Confirmed';

const globalRules = {
	required: {
		allowFalse: false,
		max:        255,
	},
};

const email = {
	...globalRules,
	email: true,
};

const username = {
	...globalRules,
};

const password = {
	...globalRules,
	min: 8,
};

const passwordConfirmation = {
	...password,
	confirmed: 'password',
};

const agb = {
	...globalRules,
};

export {
	username,
	email,
	password,
	agb,
	passwordConfirmation,
};

<template>
    <ValidationProvider ref="observer" :name="$attrs.translationLabel || $attrs.label" :rules="rules" v-slot="{ errors, valid }" v-bind="$refs" :vid="$attrs.name">
        <v-checkbox
            ref="field"
            v-model="innerValue"
            :id="$attrs.name"
            :error-messages="hasRules ? errors : []"
            :success="hasRules && valid"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        </v-checkbox>
    </ValidationProvider>
</template>

<script>
    export default {
        model: {
            prop: 'checked',
            event: 'change'
        },
        props: {
            rules: {
                type: [Object, String],
                default: ""
            },
            // must be included in props
            value: {
                type: null
            }
        },
        data: () => ({
            innerValue: false
        }),

        computed: {
            hasRules() {
                if (! this.rules) {
                    return false;
                }

                if (typeof this.rules === 'string' && this.rules === '') {
                    return false;
                }

                if (typeof this.rules !== 'object' || Array.isArray(this.rules)) {
                    return false;
                }

                return Object.keys(this.rules).length >= 1;
            }
        },

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit("change", newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },
        created() {
            if (this.value) {
                this.innerValue = this.value;
            }
        }
    };
</script>
